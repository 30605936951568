import { User, UserManager } from "oidc-client-ts"

import { API_URL, ETSY_CLIENT_ID } from "./constants/environment";

class Authentication implements LotteColourAuthentication {
  constructor() {
    this.userManager = new UserManager({
      authority: "https://www.etsy.com",
      client_id: ETSY_CLIENT_ID,
      redirect_uri: `${window.location.origin}/auth-callback`,
      scope: "listings_r",
      post_logout_redirect_uri: window.location.origin,
      metadata: {
        authorization_endpoint: "https://www.etsy.com/oauth/connect",
        token_endpoint: `${API_URL}/api/auth/token`,
      }
    })
    this.userManager.events.addAccessTokenExpiring(() => {
      console.log("token has expired...")
      // TODO: Need to test what happens on token expiration...
    })
  }

  userManager : UserManager

  logout = async () => {
    sessionStorage.removeItem('user')
    await this.userManager.signoutRedirect()
  }

  isLoggedIn = (): boolean => {
    return sessionStorage.getItem('user') != null
  }

  getUser = (): UserInfo => {
    if (this.isLoggedIn()) {
      return JSON.parse(sessionStorage.getItem('user')) as UserInfo
    }
    return null
  }

  authenticate = async (signinCallbackRedirectUrl: string) => {
    if (!this.isLoggedIn()) {
      let user = await this.userManager.getUser()

      if (!user) {
        if (window.location.pathname.toLowerCase() === '/auth-callback') {
          user = await this.userManager.signinCallback() as User

          sessionStorage.setItem(
            'user',
            JSON.stringify({
              etsyUserId: user.access_token.split('.')[0],
              accessToken: user.access_token
            } as UserInfo))

          window.location.href = signinCallbackRedirectUrl
        } else {
          await this.userManager.signinRedirect()
        }
      }
    }
  }
}

export interface UserInfo {
  etsyUserId: string
  accessToken: string
}

export interface LotteColourAuthentication {
  logout(): Promise<void>
  isLoggedIn(): boolean
  getUser(): UserInfo
  authenticate(signinCallbackRedirectUrl: string): Promise<void>
}

export const Authenticator: LotteColourAuthentication = new Authentication()